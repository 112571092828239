import { MDXProvider } from '@mdx-js/react';
import Code from './Code';
import React from 'react';

const components = {
  // h2: ({ children }) => <h2 style={headerStyles}>{children}</h2>,
  // code: ({ children }) => <code style={headerStyles}>{children}</code>,

  // 'p.inlineCode': (props) => (
  //   <code style={{ backgroundColor: 'lightgray' }} {...props} />
  // ),
  pH2: ({ children }) => <p className="h2">{children}</p>,
  pHl: ({ children }) => <p className="highlight">{children}</p>,
  video: ({ children }) => <div className="videoEmbed">{children}</div>,

  pre: ({ children: { props } }) => {
    if (props.mdxType === 'code') {
      return (
        <Code
          codeString={props.children.trim()}
          language={props.className && props.className.replace('language-', '')}
          {...props}
        />
      );
    }
  },
};

export const wrapRootElement = ({ element }) => {
return (
  <MDXProvider components={components}>{element}</MDXProvider>
)};
