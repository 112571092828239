// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-blog-template-index-js": () => import("./../../../src/components/templates/BlogTemplate/index.js" /* webpackChunkName: "component---src-components-templates-blog-template-index-js" */),
  "component---src-components-templates-single-item-template-index-js": () => import("./../../../src/components/templates/SingleItemTemplate/index.js" /* webpackChunkName: "component---src-components-templates-single-item-template-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */)
}

